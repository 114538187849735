import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,

  NavLink,
  Button
} from 'reactstrap';
import './App.css';

function App() {

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);





  return (
    <div /* className="App" */>
      <header /* className="App-header" */>
        <nav className='navBarCustom'  /* expand="md" */>
          <NavbarBrand href="/">
            <img
              src="./pepino_logo_circulo.png"
              className=""
              alt="Pepino Circular Logo"
              width="51.834px"
              height="51.834px" />

            <img
              src="./pepino_project.png"
              className=""
              alt="Pepino project"
              width="111px"
              height="auto"
              style={{ marginLeft: "10.63px" }}
            />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>

            <NavItem>
              {
                scrollPosition >= 543 && <NavLink href="https://wa.link/6qqi0d" target="_blank" className='wsp_button'>
                  <img
                    src="./wsp_icon.png"
                    className=""
                    alt="Pepino project"
                    width="29px"
                    height="30px" />
                  <span>Quiero contarles mi idea</span>
                </NavLink>
              }

            </NavItem>
          </Nav>
        </nav>
        {/*  <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <main>
        <section className='section_first'>
          <Container className='cont'>
            <div className='loader_cont'>
              <span className='ninthine'>99%</span>
              <img
                src="./pepino_loading.png"
                className="pepino_loader"
                alt="Pepino Loading"
                width="123.334px"
                height="123.334px" />
            </div>
            <h1>No esperes más para<br /> hacer realidad tu idea</h1>
            <p className="text-center lead"><span className='corazon'>♥</span> Somos <span className='caveat'>tech lovers,</span><br /> Dedicados a resolver <span className='caveat'>tech problems</span></p>
            {
              scrollPosition < 543 &&
              <div className='action_btn_cont'>
                <img src='hand.png' alt='' />
                <a href="https://wa.link/6qqi0d" target="_blank" className='wsp_button'>
                  <img
                    src="./wsp_icon.png"
                    className=""
                    alt="Pepino project"
                    width="29px"
                    height="30px" />
                  <span>Quiero contarles mi idea</span>
                </a>
                <img src='hand.png' alt='' className='invert_hand' />
              </div>
            }
          </Container>
        </section>
        <section className='section_second'>
          <Container className="my-5aa" style={{ position: "relative" }}>
            {/*  <Container > */}
            <div className='position_overlapping'>
              <Row className="text-center row_col_blocks">
                <Col xs="auto" className='cont_col_blocks'>
                  <img
                    src="./pepino_logo_circulo.png"
                    className="pepino_overlapping"
                    alt="Pepino Circular Logo" />
                  <span className='span1_block'>Creemos en el poder del equipo:</span>
                  <span className='span2_block'>Co-creamos el producto juntos</span>
                </Col>
                <Col xs="auto" className='cont_col_blocks'>
                  <img
                    src="./pepino_logo_circulo.png"
                    className="pepino_overlapping"
                    alt="Pepino Circular Logo" />
                  <span className='span1_block'>ADN Pepino:</span>
                  <span className='span2_block'>Creamos productos digitales frescos y con diferencial</span>
                </Col>
                <Col xs="auto" className='cont_col_blocks'>
                  <img
                    src="./pepino_logo_circulo.png"
                    className="pepino_overlapping"
                    alt="Pepino Circular Logo" />
                  <span className='span1_block'>Nos tomamos en serio la innovación:</span>
                  <span className='span2_block'>Siempre vamos por la “Extra Mile”</span>
                </Col>
              </Row>
            </div>
            <Row className='justify-content-center flex ayudar_cont'>
              <Col xs={12} md={6} className='text-center'>
                <img src="./iconosvarios.png" className='iconosvarios' alt="Pepino Iconos Varios" height="501px" width="auto" />
              </Col>
              <Col xs={12} md={6} >
                <h2 className='h2ayudar'>¿En que te podemos ayudar?</h2>
                <p>Conoce los principales productos que diseñamos, desarrollamos e implementamos con dedicación, profesionalismo y ♥</p>
                <ul>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    E-commerce 101
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    App development (Android + Ios)
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    web design
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    UX boost
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    SEO upgrade
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    Web performace optimization
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    Brand design
                  </li>
                  <li>
                    <img src="./ellipse.png" alt="" className='ellipsis' />
                    Newsletter service
                  </li>
                </ul>
              </Col>
            </Row>
            {/* </Container> */}
          </Container>
        </section>
        <section className='section_third'>
          <img className='luciano_photo' src="./lu.png" alt="Luciano Canziani" />
          <img className='julieta_photo' src="./juli.png" alt="Julieta Carmona" />
          <Container className="my-5aa" style={{ position: "relative" }}>
            <h2 className="team-h2">El team <span>#Pepinoproject</span></h2>

            <Row className='flex flex-column  row_team'>
              <Col className='des_col julieta_row' style={{ marginBottom: "227px" }}>
                <div>
                  <h3 className='h3nombre'><img src="./pepino_logo_circulo.png" alt="" width={40} height={40} /><span>Julieta Carmona</span></h3>
                  <h4 className='h4sub'>Diseñadora industrial, UBA</h4>
                  <p className='pdesc'>Especializada en diseño de productos digitales<br /> y experiencia del usuario (UX)</p>
                  <div className='icon_cont'>
                    <a href="https://www.behance.net/julietacarmona" target="_blank">
                      <img src='Behance-icon.svg' alt='' />
                    </a>
                    {/* <a href="https://www.linkedin.com/in/julieta-carmona/" target="_blank">
                      <img src='Linkein-icon.svg' alt='' />
                    </a> */}
                  </div>
                </div>
              </Col>
              <Col className='dev_col luciano_row'>
                <div>
                  <h3 className='h3nombre'><img src="./pepino_logo_circulo.png" alt="" width={40} height={40} /><span>Luciano Canziani</span></h3>
                  <h4 className='h4sub'>Desarrolador Full-Stack</h4>
                  <p className="pdesc">Especializado en paginas web, mejoras de SEO<br /> y rendimiento.</p>
                  <div className='icon_cont'>
                    <a href="https://lucianocanziani.netlify.app/" target="_blank">
                      <img src='gihub-icon.svg' alt='' />
                    </a>
                    {/*   <a href="https://www.linkedin.com/in/luciano-canziani/" target="_blank">
                      <img src='Linkein-icon.svg' alt='' />
                    </a> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section_four'>
          <Container className="proyectos" style={{ position: "relative" }}>
            <h2 className="proyectos-h2">Algunos proyectos...</h2>
            <p>Clientes que decidieron darle un refresh</p>
            <Row className='flex'>
              {/*   <Col className='des_col col_projects' >
                <img src="./synerfy.png" alt="" width={312} height={91} />
              </Col> */}
              <Col className='des_col col_projects' >
                <a href="https://chromewebstore.google.com/detail/firu-webtime-productivity/jhadlflplfkmelbpaoopgogcomhoeblj" target="_blank">
                  <img src="./firu.png" alt="" width={232} height={152} />
                </a>
              </Col>
              <Col className='des_col col_projects' >
                <a href="https://the-simpsons-do-it-for-her-him.vercel.app/" target="_blank">
                  <img src="./doitfor.png" alt="" width={228} height={205} />
                </a>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='section_five'>
          <Container className="proyectos" style={{
            position: "relative", display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <h2 className="final-h2">¿Te ayudamos a hacer grandioso<br />tu <span>próximo proyecto</span>?</h2>
            <a href="https://wa.link/6qqi0d" target="_blank" className='wsp_button'>
              <img
                src="./wsp_icon.png"
                className=""
                alt="Pepino project"
                width="29px"
                height="30px" />
              <span>Quiero contarles mi idea</span>
            </a>
          </Container>
        </section>

      </main>
      <footer>
        <div className='logo_footer'>
          <img
            src="./pepino_footer.svg"
            className=""
            alt="Pepino Circular Logo"
            width="51.834px"
            height="51.834px" />
          <img
            src="./pepino_project_white.svg"
            className=""
            alt="Pepino Circular Logo"
            width="-33px"
            height="51px" />
        </div>

      </footer>
    </div >
  );
}

export default App;
